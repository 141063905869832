import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import '../fonts/arvo/Arvo-Bold.ttf';
import '../fonts/arvo/Arvo-Regular.ttf';
import '../fonts/lato/Lato-Bold.ttf';
import '../fonts/lato/Lato-Regular.ttf';
import '../fonts/lato/Lato-Light.ttf';
import '../fonts/lato/Lato-LightItalic.ttf';
import './App.css';

import Home from '../Components/Home/Home';

function App() {

  return (
    <BrowserRouter>
      <Switch>
        {/*         
        <Route path="/agenda" component={Agenda} />    
        <Route path="/messagerie" component={Messagerie} /> 
        <Route path="/factures" component={Factures} /> 
        */}
        <Route path="/" component={Home} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;