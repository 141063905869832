import axios from "axios";

export async function emailContact(datas) {
    
    return axios.post(process.env.REACT_APP_API_URL+'email_contact', {
        token: process.env.REACT_APP_TOKEN,
        datas: JSON.stringify(datas),
    }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .catch(function (error) {
        console.log(error.toJSON());
    });
}