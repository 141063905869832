import { React, useEffect, useState } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import EmailIcon from '@mui/icons-material/Email';
import CircularProgress from '@mui/material/CircularProgress';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import {TextField, Button} from '@mui/material';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Keyboard, Navigation, Pagination } from 'swiper/modules';
import swal from 'sweetalert';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App/App.css';

import {emailContact} from '../../Services/EmailsServices';

function Home() {

  const [demandeContact, setDemandeContact] = useState({});
  const [nomError, setNomError] = useState();
  const [emailError, setEmailError] = useState();
  const [telephoneError, setTelephoneError] = useState();
  const [messageError, setMessageError] = useState();
  const [loading, setLoading] = useState();

  const scrollTo = (dest) => {
    document.getElementById(dest).scrollIntoView();
  }

  const updateDemande = (e) => {
    let demandeVals = {...demandeContact};
    demandeVals[e.target.id] = e.target.value;
    setDemandeContact(demandeVals);
  }

  const valideDemande = () => {

    let regex_mail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;

    let errors = 0;
    if(!demandeContact.nom || demandeContact.nom.length == 0) { setNomError('Ce champ est obligatoire'); errors++; }
    else { setNomError(null); }
    if(!demandeContact.email || demandeContact.email.length == 0) { setEmailError('Ce champ est obligatoire'); errors++; }
    else if(!regex_mail.test(demandeContact.email)) { setEmailError('Ce champ est invalide'); errors++; }
    else { setEmailError(null); }
    if(!demandeContact.telephone || demandeContact.telephone.length == 0) { setTelephoneError('Ce champ est obligatoire'); errors++; }
    else if(!/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/i.test(demandeContact.telephone)) { setTelephoneError('Ce champ est invalide'); errors++; }
    else { setTelephoneError(null); }
    if(!demandeContact.message || demandeContact.message.length == 0) { setMessageError('Ce champ est obligatoire'); errors++; }
    else { setMessageError(null); }

    if(errors == 0) {
      setLoading(true);
      emailContact(demandeContact).then(res => {
        if(res != undefined && res.data != undefined && res.data.statut == "ok") {
          swal({
            title: "Envoyé !",
            text: "Votre demande a bien été prise en compte, nous reviendrons vers vous dans les plus brefs délais.",
            icon: "success"
          });
          setLoading(false);
          setDemandeContact({});
        }
      });
    }
  }

  console.log(process.env.REACT_BASE_URL);


  return(
    <div className='custom-scrollbar'>

      <div className='header'>  
        <div className="logo">          
          <img src='/img/logo.png' className="w-100" alt="logo"/>
        </div>
        <div className='d-inline'>
          <Button className={['py-2','px-3'].join(" ")} onClick={() => scrollTo("accueil")}>Accueil</Button>
          <Button className={['py-2','px-3'].join(" ")} onClick={() => scrollTo("solutions")}>Nos solutions</Button>
          <Button className={['py-2','px-3'].join(" ")} onClick={() => scrollTo("histoire")}>Notre histoire</Button>
          <Button className={['py-2','px-3'].join(" ")} onClick={() => scrollTo("contact")}>Contact</Button>
        </div>
      </div>

      <div id="accueil" className={['fullpage','d-flex','align-items-center','bg_accueil'].join(" ")}>
        <Container>
          <Row>
            <Col md={6} className="p-4">


              <h1>Vetlife</h1>
              <h2 className='textSecondary'>La référence en devenir des logiciels pour les vétérinaires</h2>
              <p>L'ambition de la suite Vetlife, c'est de répondre à l'essentiel des enjeux des vétérinaires au travers de ses différents modules.</p>
              
              <Card className={['mt-4','black','d-inline-flex','justify-content-center'].join(" ")}>
                <Button variant="text" className={['listButton','text-center','p-3'].join(" ")} onClick={() => scrollTo("solutions")}>
                    <ArrowDownwardIcon className='me-3'/>
                    <span>Nos solutions</span>
                </Button>
              </Card>
            </Col>
            <Col></Col>
          </Row>
        </Container>                
      </div>

      <div id="solutions" className={['fullpage','bg-grey','d-flex','align-items-center','p-4'].join(" ")}>
        <Swiper
          slidesPerView={1}
          centeredSlides={true}
          breakpoints={{
            769: {
              slidesPerView: 3,
            },
          }}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[Keyboard, Navigation, Pagination]}
          className={['h-100','px-5'].join(" ")}
        >
          <SwiperSlide>
            <Paper elevation={3} className={['mx-3','p-4','p-xxl-5','text-center'].join(" ")}>
              <p><strong className={['bigText','arvo'].join(" ")}>PetConsult</strong></p>
              <p className='mt-2'><span className='smallText'>Cette application ergonique et sécurisée permet aux vétérinaires généralistes & spécialistes de <span className='textSecondary'>gérer leurs consultations, agenda, dossiers clients/patients, factures & paiements</span>. PetConsult permet également de <span className='textSecondary'>référer des cas en quelques clics</span> afin d’assurer un accès à des conseils médicaux de manière simple.<p className='mt-2'>Elle permet en outre d’échanger de manière fluide & conviviale entre vétérinaires, et avec les clients, grâce à son tchat et <span className='textSecondary'>la téléconsultation intégrée.</span></p></span></p>

              <div className={['d-inline-flex','justify-content-center',].join(" ")}>
                <Card className={['mt-4','px-2','black','d-inline-flex','justify-content-center', 'h-auto', 'w-auto'].join(" ")}>
                  <Button variant="text" className={['listButton','text-center','d-flex'].join(" ")} onClick={() => window.location.href = "https://app.vet-life.fr/"}>
                    <RocketLaunchIcon className='me-3'/>
                    <span>Se connecter</span>
                  </Button>
                </Card>
              </div>
            </Paper>
          </SwiperSlide>
          <SwiperSlide>
            <Paper elevation={3} className={['mx-3','p-4','p-xxl-5','text-center'].join(" ")}>
              <p><strong className={['bigText','arvo'].join(" ")}>PetWait</strong></p>
              <small className='textSecondary'><em>en 2024</em></small>
              <p className='mt-2'><span className='smallText'>L'avenir des salles d'attente vétérinaires, débarquera en 2024. Cette plateforme digitale a pour but de réduire l'angoisse des animaux et de leurs propriétaires en fournissant des mises à jour en temps réel sur les temps d'attente. Un outil essentiel pour une expérience vétérinaire apaisante et efficace.</span></p>
            </Paper>
          </SwiperSlide>
          <SwiperSlide>
            <Paper elevation={3} className={['mx-3','p-4','p-xxl-5','text-center'].join(" ")}>
              <p><strong className={['bigText','arvo'].join(" ")}>PetAdmin</strong></p>
              <small className='textSecondary'><em>en 2025</em></small>
              <p className='mt-2'><span className='smallText'>Préparez-vous pour l'avenir de la gestion vétérinaire avec PetAdmin. Prévu pour 2025, ce logiciel métier révolutionnaire simplifie les tâches administratives complexes, optimisant la gestion des dossiers patients, des rendez-vous et de la facturation. PetAdmin libère du temps précieux pour que les vétérinaires se concentrent sur les soins.</span></p>
            </Paper>
          </SwiperSlide>
          <SwiperSlide>
            <Paper elevation={3} className={['mx-3','p-4','p-xxl-5','text-center'].join(" ")}>
              <p><strong className={['bigText','arvo'].join(" ")}>PetDiag</strong></p>
              <small className='textSecondary'><em>en 2026</em></small>
              <p className='mt-2'><span className='smallText'>Un compagnon indispensable pour les vétérinaires, émergera en 2026. Cet outil de support au diagnostic intègre des données cliniques avancées, facilitant des diagnostics précis et rapides. Avec PetDiag, les professionnels de la santé animale disposent d'une technologie de pointe pour des soins vétérinaires de qualité supérieure.</span></p>
            </Paper>
          </SwiperSlide>
        </Swiper>
      </div>

      
      <div id="histoire" className={['fullpage','bg_histoire','d-flex','align-items-center'].join(" ")}>
        <Container>
          <Row>
            <Col md={6} className="p-4">
            </Col>
            <Col md={6} className="p-4">
              <p className={['bigText','bold','mb-4'].join(" ")}>Le compagnon digital des vétérinaires</p>

              <div className={['borderLeft','px-4','py-2','text-justify'].join(" ")}>
                <p className='mb-3'>Le monde vétérinaire évolue, et nous avons vu qu'un certain nombre de professionnels passaient un temps plus que certain sur des applications & logiciels vieillissants, complexes et pas toujours adaptés à leur besoins.</p>
                <p className='mb-1'>Ayant une expérience significative dans le monde digital, ses hauts et ses bas, nous nous sommes dit que nous pourrions les aider et, pas à pas, faire en sorte que leurs quotidiens soit meilleur.</p>
                <br></br>
                <p className='textSecondary'>C'est ainsi qu'est né Vetlife en 2024 !</p>
                <br></br>
                <p>L'avenir nous dira si l'objectif que nous nous sommes fixé en la matière sera viable. Une chose est sure, c'est que nous cherchons et continuerons à chercher à faire en sorte que le quotidien de la profession vétérinaire soit plus simple et plus concentré sur les animaux.</p>
              </div>
            </Col>
          </Row>
        </Container> 
      </div>

      <div id="contact" className={['fullpage','d-flex','align-items-center','bg_contact'].join(" ")}>
        <Container>
          
          <p className={['bigText','bold','mb-3','ps-2'].join(" ")}>Nous contacter</p>

          <Row>
            <Col md={3} className="p-4">

              <TextField 
                id="nom" 
                label="Nom complet" 
                variant="standard" 
                required={true}
                value={demandeContact.nom ? demandeContact.nom : ''}
                onChange={updateDemande}
                className="mt-2 mb-1 w-100"
                error={!!nomError}
                helperText={nomError}
              />
              <TextField 
                id="email" 
                label="Email" 
                variant="standard" 
                required={true}
                value={demandeContact.email ? demandeContact.email : ''}
                onChange={updateDemande}
                className="mt-2 mb-1 w-100"
                error={!!emailError}
                helperText={emailError}
              />

              <p className={['mt-3','smallText'].join(" ")}>Message *</p>
              <BaseTextareaAutosize 
                id="message" 
                label="Message" 
                variant="standard" 
                required={true}
                value={demandeContact.message ? demandeContact.message : ''}
                onChange={updateDemande}
                className="mt-1 mb-1 w-100"
                minRows={3}
                placeholder="..." 
                error={!!messageError}
                helperText={messageError}
              />
            </Col>
            <Col md={3} className="p-4">   

              <TextField 
                id="societe" 
                label="Société" 
                variant="standard" 
                value={demandeContact.societe ? demandeContact.societe : ''}
                onChange={updateDemande}
                className="mt-2 mb-1 w-100"
              />              
              <TextField 
                id="telephone" 
                label="Téléphone" 
                variant="standard" 
                required={true}
                value={demandeContact.telephone ? demandeContact.telephone : ''}
                onChange={updateDemande}
                className="mt-2 mb-1 w-100"
                error={!!telephoneError}
                helperText={telephoneError}
              />
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <Card className={['ms-2','mt-4','px-2','black','d-inline-flex','justify-content-center'].join(" ")}>
                <Button variant="text" className={['listButton','d-flex','align-items-center','p-3'].join(" ")} onClick={() => valideDemande()}>
                    {loading ? <CircularProgress className='me-3'/> : <EmailIcon className='me-3'/>}
                    <span>Envoyer</span>
                </Button>
              </Card>
            </Col>
          </Row>
        </Container>                
      </div>

    </div> 
  );
}

export default Home;